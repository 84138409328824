import React, { Component, Fragment } from 'react';
import './alert.less';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        {this.props.showModal && (
          <div className="modal-container-p">
            <div className="modal-content-wrapper">
              <div className="modal-content">
                <div
                  className="close-button"
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  关闭
                </div>
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  《Finger用户隐私政策》
                </div>
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  《用户个人信息协议》
                </div>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 600,
                    margin: '20px 0 10px',
                  }}
                >
                  Finger用户隐私政策
                </div>
                <div>
                  为用户提供更好、更优、更个性化的服务是我们坚持不懈的追求，也希望通过我们提供的服务可以更方便您的生活。依据法律的规定，我们将在特定情形下收集、使用、保存和披露您的个人信息。以下条款描述了我们如何收集、使用、保存和披露您的个人信息。本政策适用于
                  FingerH5页面提供的所有服务。
                </div>
                <div>
                  本政策将帮助您了解以下内容，请根据以下索引阅读相应章节：
                </div>
                <div className="sub-title" style={{ color: 'green' }}>
                  {`1.个人信息的收集  2.个人信息的使用  3.个人信息的披露  4.安全  5.第三方网站  6.未成年人保护  7.本政策的生效、变更与终止  8.其他  9.联系我们`}
                </div>
                <div className="sub-title">1.个人信息的收集</div>
                <div className="sub-title">1.1信息收集目的</div>
                <div>
                  根据国家H5页面实名制管理规定，我们保存、使用和披露个人信息是为了通过创建账户、识别用户、回应查询等方式来为您提供服务。在得到您的许可之后，“
                  FingerH5页面”会向您提供关于社区生活相关的服务、发送资讯、调查、优惠和促销信息以及其他宣传材料以为您提供更好的服务。
                </div>
                <div className="sub-title">1.2 信息收集的主要范围及方式</div>
                <div>
                  为实现“ FingerH5页面”之服务目的，“
                  FingerH5页面”会通过技术手段收集您的个人信息，通过技术手段所收集的个人信息仅限于您的姓名、出生日期、身份证号码、联系方式、住址等使用“
                  FingerH5页面”所必需的信息。
                </div>
                <div>
                  “
                  FingerH5页面”支持多类操作系统的使用：在您通过各种手机操作系统使用“
                  FingerH5页面”的过程中，“
                  FingerH5页面”所收集的信息仅限于您同意使用“
                  FingerH5页面”基本服务所必需的信息。
                </div>
                <div className="sub-title">1.3 信息推送及服务</div>
                <div>
                  您提供的信息，“
                  FingerH5页面”将最大限度尽合理之商业义务进行保密。经过您的同意，“
                  FingerH5页面”将会向您提供电子商务、生活服务、资讯、版本升级等服务。
                </div>
                <div className="sub-title">1.4 收集信息的其他方式</div>
                <div>
                  我们将采用其他方式（例如：信息下载、购买产品或服务、用户支持、反馈以及调查表）来收集您的个人信息。如果您将个人信息填写在其他开放的平台上，其他用户可能会获知您的信息。
                </div>
                <div className="sub-title">1.5 收集的其他信息</div>
                <div>
                  为了便于您使用“
                  FingerH5页面”软件，我们的服务器将会收集关于您的移动通讯设备的信息，例如型号、设备ID、互联网协议地址、您访问的时间和地点、路由数据包、信息发送人和接收数据（但不是信息本身），以及其他类似的特征记录。
                </div>
                <div className="sub-title">2.个人信息的使用</div>
                <div>
                  2.1您的个人信息将不会用于本政策载明的目的之外的其他目的，双方另有约定的除外。
                </div>
                <div>
                  2.2 匿名汇总统计数据不是“
                  FingerH5页面”所定义的个人用户信息，我们将为多种目的，包括但不限于分析和使用模式的报告等，来保存和使用此类信息。用户使用本H5页面的，视为已授权“
                  FingerH5页面”提供方以任何目的自身或许可第三方使用和披露匿名汇总统计数据的权利。
                </div>
                <div className="sub-title">3.个人信息的披露</div>
                <div>
                  3.1
                  除非本政策载明的有限披露，我们会妥善保存您的个人信息并不会泄露客户信息。
                </div>
                <div>
                  3.2
                  您于此授权，以下情形下，我们将会向第三方披露您的个人信息而无需通过您的同意：如果我们披露这些信息是用来识别、联系或向可能对我们的权利或财产、网站用户或者其他任何人（包括其他任何人的权利和财产）造成损害之行为提起诉讼之必要；法律调查之必要；
                </div>
                <div>
                  此类披露是法律规定或应向我们送达的传票或其他执法部门发出的令状之必要。
                </div>
                <div className="sub-title">4.安全</div>
                <div>
                  对我们来说，个人信息的安全非常重要。我们将尽可能保障您的个人信息安全，并实施合理的措施限制非授权访问、使用或者披露您的个人信息。尽管采取上述措施，但是您应当知晓“
                  FingerH5页面”不能完全避免与个人信息相关的安全危险。
                </div>
                <div>
                  双方同意，如发生下述情形，“幸福生活”H5页面不承担任何法律责任：
                </div>
                <div>①依据法律规定或相关政府部门的要求提供您的个人信息；</div>
                <div>②由于您的使用不当而导致任何个人信息的泄露；</div>
                <div>
                  ③任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等非“幸福生活”H5页面原因造成的服务中断、受阻等不能满足用户要求的情形；
                </div>
                <div>
                  您应当协助我们以保护您的个人信息安全。例如，不要泄露您的个人密码。
                </div>
                <div className="sub-title">5.第三方网站</div>
                <div>
                  5.1 当您点击“
                  FingerH5页面”链接的其他网站时，您已经离开我们的网站并访问其他网站，其他网站可能会收集您的个人信息或匿名数据，因此，我们无法控制、检查或者回应此类网站和网站内容。
                </div>
                <div>
                  5.2 本政策不适用于在您点击链接后的外部网站收集数据的行为。
                </div>
                <div className="sub-title">6.未成年人保护</div>
                <div>
                  “
                  FingerH5页面”非常重视对未成年人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用本H5页面服务前，应事先取得您家长或法定监护人的书面同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第9节中的联系方式与我们联系。
                </div>
                <div className="sub-title">7.本政策的生效、变更与终止</div>
                <div className="sub-title">7.1 生效</div>
                <div>
                  在此特别提醒您在登录/启动“
                  FingerH5页面”前，请认真阅读本政策，确保您充分理解本政策中各条款。请您审慎阅读并选择同意或不同意本政策。除非您接受本政策所有条款包括免除本H5页面提供方责任及限制用户权利部分的条款，否则您无法登录或启动使用本政策所涉服务。您的登录、启动使用等行为将视为对本政策的接受，并同意接受本政策各项条款的约束。您勾选“同意”按钮后，本政策生效。您若勾选“不同意”按钮后，直接退出H5页面，将无法使用“
                  FingerH5页面”。
                </div>
                <div className="sub-title">7.2 变更</div>
                <div>
                  本H5页面有权根据需要不定期地制订、修改本政策及各类规则，并在本H5页面平台公示，不再另行单独通知用户。变更后的政策和规则一经公布，立即生效。如您不同意相关变更，应当立即停止使用本H5页面平台服务。您继续使用本H5页面平台服务的，即表明您接受修订后的政策和规则。
                </div>
                <div className="sub-title">8.其他</div>
                <div>
                  8.1本政策的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和“
                  FingerH5页面”之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交
                  浙江双城网络科技集团有限公司住所地有管辖权的人民法院管辖。
                </div>
                <div>
                  8.2本政策的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
                </div>
                <div className="sub-title">9.联系我们</div>
                <div>
                  当您有其他的投诉、建议、未成年人个人信息相关问题时，请通过95059与我们联系。
                </div>
                <div
                  className="sub-title"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  FingerH5页面提供方： 浙江双城网络科技集团有限公司
                </div>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 600,
                    margin: '20px 0 10px',
                  }}
                >
                  用户个人信息协议
                </div>
                <div>
                  为用户提供更好、更优、更个性化的服务是我们坚持不懈的追求，也希望通过我们提供的服务可以更方便您的生活。依据法律的规定，我们将在特定情形下收集、使用、保存和披露您的个人信息。以下条款描述了我们如何收集、使用、保存和披露您的个人信息。本协议适用于
                  FingerH5页面提供的所有服务。
                </div>
                <div className="sub-title">1.个人信息的收集</div>
                <div className="sub-title">1.1 信息收集目的</div>
                <div>
                  根据国家H5页面实名制管理规定，我们保存、使用和披露个人信息是为了通过创建账户、识别用户、回应查询等方式来为您提供服务。在得到您的许可之后，“
                  FingerH5页面”会向您提供关于社区生活相关的服务、发送资讯、调查、优惠和促销信息以及其他宣传材料以为您提供更好的服务。
                </div>
                <div className="sub-title">1.2 信息收集的主要范围及方式</div>
                <div>
                  为实现“ FingerH5页面”之服务目的，“
                  FingerH5页面”会通过技术手段收集您的个人信息，通过技术手段所收集的个人信息仅限于您的姓名、出生日期、身份证号码、联系方式、住址等使用“
                  FingerH5页面”所必需的信息。
                </div>
                <div>
                  “
                  FingerH5页面”支持多类操作系统的使用：在您通过各种手机操作系统使用“
                  FingerH5页面”的过程中，“
                  FingerH5页面”所收集的信息仅限于您同意使用“
                  FingerH5页面”基本服务所必需的信息。
                </div>
                <div className="sub-title">1.3 信息推送及服务</div>
                <div>
                  您提供的信息，“
                  FingerH5页面”将最大限度尽合理之商业义务进行保密。经过您的同意，“
                  FingerH5页面”将会向您提供电子商务、生活服务、资讯、版本升级等服务。
                </div>
                <div className="sub-title">1.4 收集信息的其他方式</div>
                <div>
                  我们将采用其他方式（例如：信息下载、购买产品或服务、用户支持、反馈以及调查表）来收集您的个人信息。如果您将个人信息填写在其他开放的平台上，其他用户可能会获知您的信息。
                </div>
                <div className="sub-title">1.5 收集的其他信息</div>
                <div>
                  为了便于您使用“
                  FingerH5页面”软件，我们的服务器将会收集关于您的移动通讯设备的信息，例如型号、设备ID、互联网协议地址、您访问的时间和地点、路由数据包、信息发送人和接收数据（但不是信息本身），以及其他类似的特征记录。
                </div>
                <div className="sub-title">2.个人信息的使用</div>
                <div>
                  2.1您的个人信息将不会用于本协议载明的目的之外的其他目的，双方另有约定的除外。
                </div>
                <div>
                  2.2 匿名汇总统计数据不是“
                  FingerH5页面”所定义的个人用户信息，我们将为多种目的，包括但不限于分析和使用模式的报告等，来保存和使用此类信息。用户使用本H5页面的，视为已授权“
                  FingerH5页面”提供方以任何目的自身或许可第三方使用和披露匿名汇总统计数据的权利。
                </div>
                <div className="sub-title">3.个人信息的披露</div>
                <div>
                  3.1
                  除非本协议载明的有限披露，我们会妥善保存您的个人信息并不会泄露客户信息。
                </div>
                <div>
                  3.2
                  您于此授权，以下情形下，我们将会向第三方披露您的个人信息而无需通过您的同意：
                </div>
                <div>
                  如果我们披露这些信息是用来识别、联系或向可能对我们的权利或财产、网站用户或者其他任何人（包括其他任何人的权利和财产）造成损害之行为提起诉讼之必要；
                </div>
                <div>法律调查之必要；</div>
                <div>
                  此类披露是法律规定或应向我们送达的传票或其他执法部门发出的令状之必要。
                </div>
                <div className="sub-title">4.安全</div>
                <div>
                  对我们来说，个人信息的安全非常重要。我们将尽可能保障您的个人信息安全，并实施合理的措施限制非授权访问、使用或者披露您的个人信息。尽管采取上述措施，但是您应当知晓“
                  FingerH5页面”不能完全避免与个人信息相关的安全危险。
                </div>
                <div>
                  双方同意，如发生下述情形，“Finger”H5页面不承担任何法律责任：
                </div>
                <div>①依据法律规定或相关政府部门的要求提供您的个人信息；</div>
                <div>②由于您的使用不当而导致任何个人信息的泄露；</div>
                <div>
                  ③任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等非“Finger”H5页面原因造成的服务中断、受阻等不能满足用户要求的情形；
                </div>
                <div className="sub-title">
                  5.您应当协助我们以保护您的个人信息安全。例如，不要泄露您的个人密码。
                </div>
                <div className="sub-title">6.第三方网站</div>
                <div>
                  6.1 当您点击“
                  FingerH5页面”链接的其他网站时，您已经离开我们的网站并访问其他网站，其他网站可能会收集您的个人信息或匿名数据，因此，我们无法控制、检查或者回应此类网站和网站内容。
                </div>
                <div>
                  6.2 本协议不适用于在您点击链接后的外部网站收集数据的行为。
                </div>
                <div className="sub-title">7.对青少年个人信息的收集和使用</div>
                <div>
                  7.1 “
                  FingerH5页面”非常重视对青少年个人信息收集和使用的安全性和保护。我们建议，任何16周岁以下的未成年人参加网上活动应事先取得家长或其法定监护人的书面同意。
                </div>
                <div>
                  7.2 “
                  FingerH5页面”不会使用未成年人的个人信息，也不会向任何第三方透露可识别的信息。
                </div>
                <div className="sub-title">8.本协议的生效、变更与终止</div>
                <div>8.1 生效</div>
                <div>
                  在此特别提醒您在登录/启动“
                  FingerH5页面”前，请认真阅读本协议，确保您充分理解本协议中各条款。请您审慎阅读并选择同意或不同意本协议。除非您接受本协议所有条款包括免除本H5页面提供方责任及限制用户权利部分的条款，否则您无法登录或启动使用本协议所涉服务。您的登录、启动使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。您勾选“同意”按钮后，本协议生效。您若勾选“不同意”按钮后，直接退出H5页面，将无法使用“
                  FingerH5页面”。
                </div>
                <div>8.2 变更</div>
                <div>
                  本H5页面有权根据需要不定期地制订、修改本协议及各类规则，并在本H5页面平台公示，不再另行单独通知用户。变更后的协议和规则一经公布，立即生效。如您不同意相关变更，应当立即停止使用本H5页面平台服务。您继续使用本H5页面平台服务的，即表明您接受修订后的协议和规则。
                </div>
                <div className="sub-title">9.其他</div>
                <div>
                  9.1本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和“
                  FingerH5页面”之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交
                  浙江双城网络科技集团有限公司住所地有管辖权的人民法院管辖。
                </div>
                <div>
                  9.2本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
                </div>
                <div
                  className="sub-title"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  FingerH5页面提供方： 杭州妥妥网络科技有限公司
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
