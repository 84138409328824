import React, { useEffect, useState } from 'react';
import styles from './style.module.less';

export default ({ showCopyRight }) => {
  const [copyRight, setCopyRight] = useState(false);

  useEffect(() => {
    setCopyRight(showCopyRight);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.sectionList}>
          <div className={styles.section}>
            <h3>产品&服务</h3>
            <ul>
              <li>少儿钢琴</li>
              <li>成人钢琴</li>
              <li>古典吉他</li>
              <li>琴房练琴</li>
              <li>租琴 | 买琴</li>
              <li>
                <div className={styles.service}>
                  {'微信公众号'}
                  <div className={styles.serviceLogo}>
                    <div className={styles.serviceLogoWrapper}>
                      <img
                        style={{ width: 150 }}
                        src={require('images/wechat-service-logo.jpg')}
                      />
                    </div>
                    <div className={styles.serviceLogoTip}>
                      微信扫一扫 关注我们
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className={styles.section}>
            <h3>权威机构合作</h3>
            <ul>
              <li>人民音乐出版社</li>
              <li>美国IMPACT音乐家协会</li>
              <li>绿城教育集团</li>
              <li>元璟资本</li>
              <li>大众点评</li>
              <li>美团</li>
              <li>支付宝</li>
            </ul>
          </div>

          <div className={styles.section}>
            <h3>关于我们</h3>
            <ul>
              <li style={{ width: 200 }}>
                「Finger指教音乐中心」隶属于杭州指教艺术培训有限公司，专注音乐教育。校区教学内容包括钢琴、声乐、古典吉他等各类乐器，用专业的品质态度为少儿用户、成人用户提供最优质的美式钢琴教学。
              </li>
              <li>电话：0571-86079102</li>
              <li>
                地址：
                <span className={styles.address}>
                  杭州市西湖区文二西路808号西溪壹号23幢
                </span>
              </li>
              <li>
                <a href="#book-form">
                  <div className={styles.book}>预约免费体验课</div>
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.section}>
            <h3>校区</h3>
            <ul>
              <li>
                西溪校区总部
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州市西湖区文二西路808号西溪壹号23幢
                </div>
              </li>
              <li>
                大悦城校区
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州拱墅区莫干山路中粮大悦城L5-19
                </div>
              </li>
              <li>
                黄龙旗舰校区
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州市西湖区黄龙世纪广场B座1层23号
                </div>
              </li>
              <li>
                中大银泰校区
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州市下城区东新路822号中大银泰城2层L241
                </div>
              </li>
              <li>
                西溪印象城校区
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州市余杭区五常大道1号一期3楼
                </div>
              </li>
              <li>
                绿城指教社区音乐中心（杨柳郡校区）
                <div className={styles.schoolAddr}>
                  <img
                    className={styles.schoolAddrIcon}
                    style={{ width: 16 }}
                    src={require('images/icon-loc.png')}
                  />
                  杭州市上城区彭埠街道九和路660号杨柳郡园三区13幢底商1绿城指教社区音乐中心
                </div>
              </li>
            </ul>
          </div>
        </div>
        <p className={styles.rights}>
          {copyRight
            ? '版权：东莞市幽梦电子商务有限公司'
            : '©2019 杭州指教艺术培训有限公司 版权所有'}
        </p>
      </div>
    </div>
  );
};
