import React, { useCallback, useEffect, useState, Fragment } from 'react';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import axios from 'axios';
import cls from 'classnames';
import get from 'lodash/get';
import RulesAlert from 'components/RulesAlert';

import useCountDown from 'utils/useCountDown';

import styles from './style.module.less';

function BookForm({
  title,
  description,
  instrument,
  customer,
  query = {},
  placeholders = {},
  bgColor = 'light',
  onSubmit: onSubmitt,
  onDirty,
}) {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { dirty },
  } = useForm({
    mode: 'onChange',
  });

  const [state, setState] = useState({ ruleChecked: true, showModal: false });
  const updateState = (partialState) =>
    setState((oldState) => ({
      ...oldState,
      ...partialState,
    }));

  const [c, { start }] = useCountDown();
  const onSubmit = useCallback(
    (value) => {
      if (!state.ruleChecked) {
        window.alert('请仔细阅读个人信息收集使用规则并选中');
        return;
      }
      const qs = parse(window.location.search);

      axios
        .post(
          `${process.env.API_DOMAIN}/webapi/addPotentialUser`,
          {
            ...value,
            ...query,
            instrumentInterest: [instrument === 'piano' ? 6 : 5],
            customerTypes: [customer === 'child' ? 1 : 2],
            source: qs['tt_source'] || '8',
          },
          {
            headers: {
              Authorization:
                'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
            },
          }
        )
        .then(({ data: { status, msg } }) => {
          onSubmitt && onSubmitt();

          if (status === 10025) {
            alert('该手机号已领取！');
          } else if (status === 0) {
            alert('预约成功！');
          } else if (msg) {
            alert(msg);
          } else {
            alert('预约成功！');
          }
        })
        .catch(() => alert('预约失败，请重试！'));
    },
    [onSubmitt, query, state.ruleChecked, instrument, customer]
  );
  const schoolId = watch('schoolId');
  const mobilePhone = watch('mobilePhone');
  const disabledM = !!c || !mobilePhone || !!get(errors, 'mobilePhone.message');
  const onSendM = useCallback(() => {
    axios
      .post(
        `${process.env.API_DOMAIN}/webapi/sendPotentialUserVerifyCode`,
        { accountNo: mobilePhone },
        {
          headers: {
            Authorization:
              'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
          },
        }
      )
      .then(() => {
        alert('验证码已发送！');
        start();
      })
      .catch(() => alert('验证码发送失败，请重试！'));
  }, [mobilePhone, start]);

  // 埋点
  useEffect(() => {
    if (dirty) {
      onDirty && onDirty();
    }
  }, [dirty, onDirty]);

  return (
    <Fragment>
      <RulesAlert
        showModal={state.showModal}
        closeModal={() => {
          updateState({ showModal: false });
        }}
      />
      <section id="book-form" className={cls([styles.container, bgColor])}>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.desc}>{description}</p>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div
              className={cls([
                styles.formItem,
                get(errors, 'schoolId.message') && 'error',
              ])}
            >
              <select
                className={cls([schoolId && 'selected'])}
                defaultValue=""
                name="schoolId"
                placeholder={placeholders.schoolId}
                ref={register({ required: '校区不能为空' })}
              >
                <option value="" disabled hidden>
                  选择校区
                </option>
                <option value={6}>西溪湿地总校</option>
                <option value={5}>中大银泰校区</option>
                <option value={7}>大悦城校区</option>
                <option value={8}>黄龙旗舰校区</option>
                <option value={11}>西溪印象城校区</option>
                <option value={15}>绿城指教社区音乐中心（杨柳郡校区）</option>
              </select>
              <div className={styles.arrowD}>
                <img
                  src={require('images/icon-arrow-d.png')}
                  style={{ verticalAlign: 'super', width: 22, height: 12 }}
                  alt="arrow"
                />
              </div>
              {/* Error Message */}
              <div
                className={cls([
                  styles.formErrorMsg,
                  get(errors, 'schoolId.message') && 'show',
                ])}
              >
                {get(errors, 'schoolId.message')}
              </div>
            </div>
            <div
              className={cls([
                styles.formItem,
                get(errors, 'realName.message') && 'error',
              ])}
            >
              <input
                type="text"
                name="realName"
                placeholder={placeholders.realName}
                autoComplete="off"
                ref={register({ required: '姓名不能为空' })}
              />
              {/* Error Message */}
              <div
                className={cls([
                  styles.formErrorMsg,
                  get(errors, 'realName.message') && 'show',
                ])}
              >
                {get(errors, 'realName.message')}
              </div>
            </div>
            <div
              className={cls([
                styles.formItem,
                get(errors, 'mobilePhone.message') && 'error',
              ])}
            >
              <input
                type="tel"
                name="mobilePhone"
                placeholder={placeholders.mobilePhone}
                autoComplete="off"
                ref={register({
                  required: '手机号不能为空',
                  pattern: {
                    value: /^1[0-9]{10}$/,
                    message: '手机号不符合规范',
                  },
                })}
              />

              <button
                className={styles.imBtn}
                type="button"
                disabled={disabledM}
                onClick={onSendM}
              >
                {c ? `重新获取（${c}s)` : '获取验证码'}
              </button>

              {/* Error Message */}
              <div
                className={cls([
                  styles.formErrorMsg,
                  get(errors, 'mobilePhone.message') && 'show',
                ])}
              >
                {get(errors, 'mobilePhone.message')}
              </div>
            </div>
            <div
              className={cls([
                styles.formItem,
                get(errors, 'verifyCode.message') && 'error',
              ])}
            >
              <input
                type="text"
                name="verifyCode"
                placeholder={placeholders.verifyCode}
                autoComplete="off"
                ref={register({
                  required: '验证码不能为空',
                  pattern: { value: /^[0-9]{6}$/, message: '验证码不符合规范' },
                })}
              />
              {/* Error Message */}
              <div
                className={cls([
                  styles.formErrorMsg,
                  get(errors, 'verifyCode.message') && 'show',
                ])}
              >
                {get(errors, 'verifyCode.message')}
              </div>
            </div>

            <div
              style={{
                height: '40px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                type="checkbox"
                name="ruleChecked"
                defaultValue={true}
                checked={state.ruleChecked}
                style={{ marginRight: 10 }}
                onChange={() =>
                  updateState({ ruleChecked: !state.ruleChecked })
                }
              />
              <span>
                我已知晓并接受
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    updateState({ showModal: true });
                  }}
                >
                  《个人信息收集使用规则》
                </span>
              </span>
            </div>
            <div
              className={
                state.ruleChecked ? styles.submit : styles.submitUnable
              }
            >
              <input type="submit" value="免费预约" />
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
}

BookForm.propTypes = {
  instrument: PropTypes.oneOf(['piano', 'voice']).isRequired,
  customer: PropTypes.oneOf(['adult', 'child']).isRequired,
};

export default BookForm;
