import React from 'react';
import styles from './style.module.less';

export default ({ ...props }) => (
  <div className={styles.container}>
    <svg
      className={styles.close}
      width="48"
      height="48"
      viewBox="0 0 1024 1024"
      onClick={props.onClose}
    >
      <path
        fill="#ffffff"
        d="M814.060 781.227q-67.241-67.241-269.773-269.773 67.241-67.241 269.773-269.773 5.671-6.481 5.671-12.962 0 0-0.81-0.81 0-6.481-4.861-9.722-4.861-4.051-11.342-4.861-0.81 0-0.81 0-5.671 0-11.342 4.861-89.924 89.924-269.773 269.773-67.241-67.241-269.773-269.773-4.861-4.861-12.962-4.861-7.291 0.81-10.532 4.861-5.671 5.671-5.671 11.342 0 6.481 5.671 12.152 89.924 89.924 269.773 269.773-67.241 67.241-269.773 269.773-11.342 11.342 0 23.494 12.152 11.342 23.494 0 89.924-89.924 269.773-269.773 67.241 67.241 269.773 269.773 5.671 5.671 11.342 5.671 5.671 0 12.152-5.671 4.861-5.671 4.861-12.962 0-6.481-4.861-10.532z"
      />
    </svg>
    <div className={styles.wrapper}>
      <video
        className={styles.video}
        autoPlay
        controls
        preload="auto"
        x-webkit-airplay="allow"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="landscape"
        {...props}
      />
    </div>
  </div>
);
