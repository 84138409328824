import { useCallback, useEffect, useState } from 'react';

export default function useCountDown(times = 60) {
  const [t, setT] = useState(0);
  const [s, setS] = useState(false);
  const start = useCallback(() => setS(true), []);
  const stop = useCallback(() => setS(false), []);

  useEffect(() => {
    if (!s) return;

    const timer = setInterval(() => {
      setT((x) => {
        if (x - 1 <= 0) {
          clearInterval(timer);
          setS(false);

          return 0;
        }

        return x - 1;
      });
    }, 1000);

    setT(times);

    return () => {
      clearInterval(timer);
      setT(0);
      setS(false);
    };
  }, [s, times]);

  return [t, { start, stop }];
}
