import React from 'react';
import scrollTo from 'utils/scrollTo';
import styles from './style.module.less';

export default ({ onClick }) => {
  return (
    <div className={styles.container}>
      <span
        style={{ display: 'block' }}
        onClick={() => {
          onClick && onClick();
          scrollTo({ id: 'book-form' });
        }}
      >
        <img
          style={{ width: 150, height: 150 }}
          src={require('images/gotop.png')}
        />
      </span>
    </div>
  );
};
