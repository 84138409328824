import { useEffect, useState, useCallback } from 'react';
import defaults from 'lodash/defaults';

export default function useLoop([initialCO = 0, initialCI = 0], options) {
  const {
    totalSize: [totalSizeO, totalSizeI],
    timeout,
    autoplay,
    loop,
  } = defaults(options, {
    autoplay: true,
    loop: true,
    totalSize: [0, 0],
    timeout: 5000,
  });
  const [curr, setCurr] = useState({ co: initialCO, ci: initialCI });
  const [stop, setStop] = useState(false);
  const { co, ci } = curr;

  // 播放
  const play = useCallback(() => setStop(false), []);
  // 暂停
  const pause = useCallback(() => setStop(true), []);
  // 下一步
  const next = useCallback(() => {
    setStop(false);
    setCurr(({ co, ci }) => {
      // total size of ci
      const total =
        typeof totalSizeI === 'number' ? totalSizeI : totalSizeI[co];

      if (loop) {
        if (co + 1 >= totalSizeO && ci + 1 >= total) {
          return { co: 0, ci: 0 };
        }
      }

      if (co + 1 >= totalSizeO && ci + 1 >= total) {
        return { co, ci };
      }

      if (ci + 1 >= total) {
        return { co: co + 1, ci: 0 };
      }

      return { co, ci: ci + 1 };
    });
  }, [loop, totalSizeO, totalSizeI]);
  // 上一步
  const prev = useCallback(() => {
    setStop(false);
    setCurr(({ co, ci }) => {
      if (loop) {
        if (co - 1 < 0 && ci - 1 < 0) {
          const total =
            typeof totalSizeI === 'number'
              ? totalSizeI
              : totalSizeI[totalSizeO - 1];

          return { co: totalSizeO - 1, ci: total - 1 };
        }
      }

      if (co - 1 < 0 && ci - 1 < 0) {
        return { co: 0, ci: 0 };
      }

      if (ci - 1 < 0) {
        return { co: co - 1, ci: 0 };
      }

      return { co, ci: ci - 1 };
    });
  }, [loop, totalSizeO, totalSizeI]);

  useEffect(() => {
    if (stop || !autoplay) return;

    const timer = setInterval(next, timeout);

    return () => clearInterval(timer);
  }, [stop, autoplay, next, timeout]);

  return [
    co,
    ci,
    {
      play,
      pause,
      next,
      prev,
      goto: (co, ci) => {
        const state = {};

        if (typeof co === 'number') {
          state.co = co;
        }

        if (typeof ci === 'number') {
          state.ci = ci;
        }

        setCurr((s) => ({ ...s, ...state }));
      },
    },
  ];
}
