import { useEffect, useState, useCallback } from 'react';
import defaults from 'lodash/defaults';

export default function useLoop(initialCurr, options) {
  const { totalSize, timeout, autoplay, loop } = defaults(options, {
    autoplay: true,
    loop: true,
    totalSize: 0,
    timeout: 5000,
  });
  const [curr, setCurr] = useState(initialCurr);
  const [stop, setStop] = useState(false);
  // 播放
  const play = useCallback(() => setStop(false), []);
  // 暂停
  const pause = useCallback(() => setStop(true), []);
  // 下一步
  const next = useCallback(() => {
    setStop(false);
    setCurr((c) => {
      if (loop) {
        return (c + 1) % totalSize;
      }

      if (c === totalSize - 1) return c;

      return c + 1;
    });
  }, [loop, totalSize]);
  // 上一步
  const prev = useCallback(() => {
    setStop(false);
    setCurr((c) => {
      if (loop) {
        return (c + totalSize - 1) % totalSize;
      }

      if (c === 0) return c;

      return c - 1;
    });
  }, [loop, totalSize]);

  useEffect(() => {
    if (stop || !autoplay) return;

    const timer = setInterval(
      () => setCurr((c) => (c + 1) % totalSize),
      timeout
    );

    return () => clearInterval(timer);
  }, [stop, autoplay, totalSize, timeout]);

  return [curr, { play, pause, next, prev, goto: setCurr }];
}
