import React, { useCallback, useRef, useEffect } from 'react';
import cls from 'classnames';
import { getWidth } from 'dom-lib';
import onResize, { unbind } from 'element-resize-event';

import useLoop from 'utils/useLoop';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'gray', list }) => {
  const len = (list || []).length;
  const [curr, { goto, pause, play }] = useLoop(0, { totalSize: len });
  const cardsWrapperEl = useRef(null);
  const cardsEl = useRef(null);
  const transHandler = useCallback(() => {
    const wrapperWidth = getWidth(cardsWrapperEl.current);
    // (335 + 15 + 15) * len
    const cardWidth = len >= 5 ? 330 : 365;
    const contentWidth = cardWidth * len;
    const showNum = Math.floor(wrapperWidth / cardWidth);

    if (showNum >= len) {
      if (cardsEl.current) {
        cardsEl.current.style.transform = 'translateX(0px)';
      }

      return;
    }

    if (curr >= showNum) {
      if (cardsEl.current) {
        const transX = Math.min(
          cardWidth * curr + cardWidth / 2 - wrapperWidth / 2,
          contentWidth - wrapperWidth
        );

        cardsEl.current.style.transform = `translateX(${-transX}px)`;
      }

      return;
    }

    if (cardsEl.current) {
      cardsEl.current.style.transform = 'translateX(0px)';
    }
  }, [curr, len]);

  // 监听 页面大小调整
  useEffect(() => {
    onResize(document.body, transHandler);

    return () => unbind(document.body, transHandler);
  }, [transHandler]);

  // curr 变动，计算偏移
  useEffect(transHandler, [transHandler]);

  return (
    <div id="card-list" className={cls([styles.container, bgColor])}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>

        <div ref={cardsWrapperEl} className={styles.cardsWrapper}>
          <div ref={cardsEl} className={styles.cards}>
            {(list || []).map(({ src, itemStyle, title, content }, i) => {
              return (
                <div
                  key={i}
                  className={cls([
                    styles.cardWrapper,
                    i === curr && 'active',
                    len >= 5 && 'small',
                  ])}
                >
                  <div
                    className={cls([styles.card, len >= 5 && 'small'])}
                    onMouseEnter={() => {
                      pause();
                      goto(i);
                    }}
                    onMouseLeave={() => play()}
                  >
                    <img
                      className={styles.cardCover}
                      src={src}
                      style={itemStyle}
                    />
                    <div className={styles.cardMask} />
                    <div className={styles.cardContent}>
                      <h3>{title}</h3>
                      {content && content.length ? (
                        <ul>
                          {(content || []).map((x, i) => (
                            <li key={i}>{x}</li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>

                  <div className={styles.cardTitle}>{title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
