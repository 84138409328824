import React, { useMemo } from 'react';
import { Link, withPrefix } from 'gatsby';
import styles from './style.module.less';

export default ({ instrument, customer }) => {
  const baseURL = useMemo(() => {
    //老旧项目的跳转域名
    switch (process.env.DEPLOY_ENV) {
      case 'development':
        return 'https://stable.fingerapp.cn';
      case 'preview':
        return 'https://stable.fingerapp.cn';
      case 'production':
        return 'https://www.finger66.com';
      default:
        return 'https://stable.fingerapp.cn';
    }
  }, []);

  return (
    <div id="header" className={styles.container}>
      <div className={styles.wrapper}>
        <Link to={withPrefix('/')} className={styles.logo}>
          <img
            style={{ width: 333 }}
            src={require('images/logo.png')}
            alt="Finger指教音乐中心"
          />
        </Link>

        <ul className={styles.menu}>
          {(instrument !== 'piano' || customer !== 'child') && (
            <li className={styles.menuItem}>
              <a href="https://zhijiao.finger66.com/piano/child">少儿钢琴</a>
            </li>
          )}
          {(instrument !== 'piano' || customer !== 'adult') && (
            <li className={styles.menuItem}>
              <a href="https://zhijiao.finger66.com/piano/adult">成人钢琴</a>
            </li>
          )}
          {(instrument !== 'voice' || customer !== 'adult') && (
            <li className={styles.menuItem}>
              <a href="https://zhijiao.finger66.com/voice/adult">成人声乐</a>
            </li>
          )}
          <li className={styles.menuItem}>
            <a href={baseURL + '/activity/school-website/#/teacher'}>
              老师介绍
            </a>
          </li>
          <li className={styles.menuItem}>
            <img
              className={styles.iconTel}
              style={{ width: 14 }}
              src={require('images/icon-tel.png')}
              alt="telephone"
            />
            0571-86079102
          </li>
        </ul>
      </div>
    </div>
  );
};
